//* GENERAL **//
body {
    background-color: #FFF;
}

p,
li {
    font-size: 18px;
    line-height: 32px;
}

.pagetitle {
    color: $primary;
    line-height: 1.35;
    font-size: 40px;
    font-weight: 900;
    font-family: Ubuntu, Arial, sans-serif;
    letter-spacing: 0!important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-heading;
    letter-spacing: 0em!important;
}

h1 {
    color: $primary;
    font-weight: 900;
    font-size: 40px;
    line-height: 1.35;
}

h2 {
    color: $primary;
    font-weight: 700;
    font-size: 30px;
    line-height: 1.35;
    margin: 15px 0;
}

h3 {
    font-size: 26px;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 25px;
    color: $dark-gray;
    text-transform: none;
}

section.section {
    background-color: #FFF;
    padding: 0 0 50px 0;
}

section .container {
    .content-list {
        li {
            padding: 2px 0px 2px 10px;
            margin-left: 20px;
            &::marker {
                color: $primary;
                content: url("data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='utf-8'?%3e%3c!-- Generator: Adobe Illustrator 26.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e%3csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='19px' viewBox='0 0 20 18.68' style='enable-background:new 0 0 20 18.68;' xml:space='preserve'%3e%3cstyle type='text/css'%3e .st0%7bfill:%23afafaf;%7d %3c/style%3e%3cpath class='st0' d='M6.96,18.68c-0.67,0-1.27-0.49-1.49-1.22c-0.85-2.8-3.08-4.89-4.13-5.73l0,0l-0.57-0.37 C0.29,11.03,0,10.46,0,9.83c0-0.98,0.71-1.78,1.58-1.78c0.28,0,0.56,0.09,0.8,0.25l0.02,0.01c0.2,0.14,1.24,0.86,2.44,2.1 c0.43,0.45,0.84,0.92,1.21,1.38l0.65,0.82l0.47-0.96c1.81-3.7,5.62-7.52,11.03-11.05c0.42-0.27,0.92-0.44,1.34-0.44 c0.28,0,0.42,0.08,0.45,0.1C20.06,0.49,19.8,1,19.03,1.61C15,4.82,11.97,9.67,10.52,12.28c-1.5,2.7-1.96,4.74-2.01,4.97L8.5,17.27 c-0.14,0.79-0.72,1.36-1.44,1.41C7.03,18.68,6.99,18.68,6.96,18.68z'/%3e%3c/svg%3e");
            }
        }
    }
}

section>.container:first-of-type {
    padding-top: 50px;
}

.bg-primary {
    background-color: $primary !important;
}

.bg-mute {
    background-color: $gray;
    border-top: 1px solid $border-gray;
    border-bottom: 1px solid $border-gray;
}

ul {
    .dropdown-menu {
        display: block!important;
        li {
            a {
                padding-left: 15px!important;
            }
        }
    }
}

.card {
    &-header {
        background-color: $primary!important;
        color: white;
        h2 {
            color: white;
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }
    &-mute {
        box-shadow: 0px 5px 10px 1px rgb(0 0 0 / 20%);
        border: none;
        background-color: $gray;
        border-radius: 6px;
    }
}

.tel,
.mail {
    position: fixed;
    display: inline-block;
    background-color: $primary;
    //width: 210px;
    border-radius: 6px 0 0 6px;
    padding: 10px;
    color: #fff;
    //right: -165px;
    transition: all .4s ease-in-out;
    opacity: 50%;
    z-index: 1030;
    &:hover {
        right: 0;
        opacity: 100%;
        box-shadow: 0px 5px 10px 1px rgb(0 0 0 / 20%);
    }
    a {
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        font-family: $font-heading;
        padding-left: 10px;
    }
}

.tel {
    width: 220px;
    right: -175px;
    top: 245px;
}

.mail {
    top: 185px;
    width: 240px;
    right: -195px;
}

.to-top {
    --offset: 320px;
    position: fixed;
    bottom: 10px;
    right: 0px;
    margin-right: 10px;
    margin-top: calc(100vh + var(--offset));
    text-decoration: none;
    padding: 10px 17px 10px 17px;
    font-size: 22px;
    color: #fff;
    background: $primary;
    border-radius: 6px;
    white-space: nowrap;
    &:hover {
        color: #fff;
        text-decoration: none;
        background: $primary-light
    }
}

.feature-box.feature-box-style-2 .feature-box-icon {
    color: $primary !important;
}

//** BUTTONS **//
.btn {
    font-size: 16px;
    font-family: $font-heading;
    text-transform: uppercase;
    font-weight: 700;
    border-width: 1px;
    border-style: solid;
    padding: 15px 30px;
    box-shadow: $btn-shadow;
    -webkit-box-shadow: $btn-shadow;
    -moz-box-shadow: $btn-shadow;
    transition: all 0.3s ease-in-out;
    &-light {
        background-color: #FFF!important;
        border-color: #fff!important;
        color: $primary!important;
        &:hover {
            color: $dark-gray!important;
            background-color: $gray;
            border-color: $gray;
        }
    }
    &-primary {
        background-color: $primary!important;
        border-color: $primary!important;
        color: #fff!important;
        &:hover {
            background-color: $primary-light!important;
            border-color: $primary-light!important;
        }
    }
    &:hover,
    &:active,
    &:focus {
        box-shadow: $btn-shadow-hover!important;
        -webkit-box-shadow: $btn-shadow-hover!important;
        -moz-box-shadow: $btn-shadow-hover!important;
    }
}

.btn-primary {
    background-color: #CCC;
    border-color: #CCC #CCC #b3b3b3;
    color: #FFF;
}

//** HEADER **//
#header a {
    font-family: $font-heading,
}

.nav li:hover>a {
    background-color: $primary-extra-light !important;
}

//* HERO *//
.hero {
    width: 100%;
    min-height: 550px;
    background: $primary;
    background: linear-gradient(180deg, $primary 0%, $primary-light 100%);
    .container {
        padding: 0px 15px!important;
    }
    .row {
        min-height: 500px;
    }
    .col {
        &-md5,
        &-md-7 {
            padding: 30px 15px;
        }
    }
    h1 {
        color: #fff;
        margin-bottom: 15px;
        font-size: 50px;
    }
    p {
        color: #fff;
        margin-bottom: 40px;
    }
    .lead {
        line-height: 1.6;
        font-weight: 400;
        letter-spacing: 0;
    }
}

//** BEWERTUNGEN CAROUSEL **//
ul.carousel__list {
    min-height: 200px;
}

.carousel {
    &__indicator {
        display: none!important;
    }
}

//** FLEX MODIFICATION **//
.col-md-9 {
    flex: 0 0 66.666%;
    max-width: 66.666%;
}

//** SIDEBAR **//
.foto-start {
    border: none;
    border-radius: 4px;
    box-shadow: 0px 0px 5px 4px rgb(50 50 50 / 5%);
}

.blau {
    color: $primary;
}

.dunkelblau {
    font-size: 18px;
    margin: 0 0 10px 0;
}

//** TEASER **//
.thumb-info {
    .thumb-info-title {
        position: relative;
        font-weight: 700;
    }
    img {
        width: 200px;
    }
}

.thumb-info.thumb-info-no-borders.thumb-info-no-borders-rounded img {
    border-radius: 4px 4px 0 0!important;
}

//** FAQ **//
.accordion {
    h2 {
        color: $dark-gray;
        font-size: 26px;
    }
    .vs-collapse {
        font-size: 18px;
        &-item {
            cursor: default;
        }
        &-item--header {
            padding: 10px 0!important;
            cursor: pointer;
        }
        &-item--icon-header {
            color: $primary;
        }
        h3 {
            color: $primary;
            font-size: 18px;
            margin: 0;
        }
    }
    .con-content--item {
        padding: 10px 0!important;
    }
    p {
        margin: 0!important;
        padding: 0!important;
    }
    ul {
        list-style-type: disc;
        padding: 0 0 0 20px;
        li {
            display: list-item;
            &::marker {
                color: $primary;
            }
        }
    }
}

//** REFERENZEN **//
.list.list-icons.list-icons-sm li>[class*=fa-]:first-child {
    top: 9px!important;
}

.referenzen {
    h3 {
        font-size: 18px;
        color: #777;
        margin: 0;
    }
    .vs-collapse {
        padding: 0;
    }
}

//** KONTAKTFORMULAR **//
input[type="checkbox"] {
    height: 15px;
    box-sizing: border-box;
    padding: 0;
    display: inline;
    width: auto;
    margin: 30px 5px 40px 0;
}

.anchor {
    display: block;
    position: relative;
    top: -150px;
    visibility: hidden;
}

//** Cookie Disclaimer **//
.cookies-accept {
    font-size: 1rem !important;
    line-height: 1.5 !important;
    font-weight: 400 !important;
    border-radius: 0.25rem !important;
    padding: 0.375rem 0.75rem !important;
}

//** BREAKPOINTS **//
@media (max-width: 550px) {
    h1 {
        font-size: 32px;
    }
    .hero h1 {
        font-size: 40px;
    }
    h2 {
        font-size: 26px;
    }
}

.text-row {
    background-color: #f4f4f4;
    border: none;
    border-radius: 6px;
    box-shadow: 0 5px 10px 1px rgb(0 0 0 / 20%);
}

.title {
    font-weight: 700;
    font-size: 30px;
    margin: 15px 0;
    color: #0062a1;
    line-height: 1.35;
    font-family: Ubuntu, Arial, sans-serif;
    letter-spacing: 0!important;
}