@import '../vendor/bootstrap/css/bootstrap.css'; //Bootstrap 4
//@import './bootstrap.scss';   //Bootstrap 5
@import '../vendor/fontawesome-free/css/all.purify.css';
//@import '../vendor/animate/animate.purify.css';
@import './custom-variables.scss';
//@import './custom-variables-access.scss';
//@import './custom-variables-excel.scss';
@import './theme.scss';
@import './theme-elements.scss';
//@import '../vendor/rs-plugin/css/settings.css';
//@import '../vendor/rs-plugin/css/layers.css';
//@import '../vendor/rs-plugin/css/navigation.css';
@import './theme-custom.scss';
@import 'material-icons/iconfont/material-icons.css';
@import 'vuesax/dist/vuesax.css';
@import './fonts.scss';
@import './custom.scss';
@import './menuoben.scss';
@import './rating.scss';
@import './svg.scss';
@import './layouts.scss';
@import './hero.scss';